
/* Shift tags down to align with rest of row */
.multiselect {
  top: 5px;
}


/* Reduce size of font when text-xxs specified (i.e. on Productions View) */
.multiselect.text-xxs .multiselect__tags {
  font-size: 10px;
}

/* Reduce size of font when text-xs specified (i.e. on Productions View) */
.multiselect.text-xs .multiselect__tags {
  font-size: 12px;
}

.multiselect--disabled {
    background: none !important;
    opacity: 1 !important;
}


/* Change tag to to red */
.multiselect__tag  {
  /* padding: 4px 26px 4px 4px; */
  background:  #f87171 !important;
}

.multiselect__tag-icon {
  background:  #f87171 !important;
}

/* Found by inspecting css for multiselect__tag element when rendered */
/* Created to allow coloured tags instead of default bg colour in min.css */
.ajs__multiselect__tag {
    position: relative;
    display: inline-block;
    /* padding: 4px 26px 4px 4px; */
    padding: 6px;
    border-radius: 6px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    /* background: #41b883; Remove this to allow tailwind background to be used */
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}


/* Remove border around tags */
.multiselect__tags {
  border: none !important;
  min-width: 150px !important;
  background: None !important
  /*padding: 0 !important*/
}

/* Remove drop down arrow */
.multiselect__select {
  display: none !important;
}

/* Remove placehodler to avoid restyling */
.multiselect__placeholder {
  display: none !important
}

.auto-tag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 14px;
  top: 5px
}

