@import "./reset.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./buttons.css";
@import "./form.css";
@import "./nprogress.css";
@import "./multiselect.css";


